@import '../../assets/scss/variables';

.main-section{
    .header-text-section h1{
        opacity: 0;
    }
    .learn-arrow{
        opacity: 0;
    }
    &.main-bg{
        .header-text-section h1{
            opacity: 1;
        }
        .learn-arrow{
            opacity: 1;
        }
    }
}
.sideNav{
    position: fixed;
    right: 2rem;
    top: 50%;
    z-index: 999;
    li{
        color: $white;
        list-style: none;
        cursor: pointer;
        position: relative;
        margin: 8px 0px;
        .dot{
            width: 15px;
            height: 15px;
            border-radius: 20px;
            background: $white;
            display: inline-block;
        }
        .label{
            display: none;
            position: absolute;
            left: -6rem;
            top: -3px;
            font-size: 13px;
            background: #241f2b;
            padding: 4px 10px;
            border-radius: 20px;
        }
        &:hover{
            .dot{
                background: $primary;
            }
            .label{
                display: block;
            }
        }
    }
}

.header-text-section{
    padding-bottom: 30rem;
    padding-top: 18rem;
    position: relative;
    z-index: 1;
    h1{
        font-size: 125px;
        font-weight: 600;
        line-height: 134px;
        letter-spacing: -6.4px;
        margin: 0px;
        color: $white;
        padding-left: 8rem;
        span{
            color: $primary;
            display: block;
        }
    }
    .learn-arrow{
        color: $white;
        text-align: center;
        transform: rotate(-90deg);
        position: absolute;
        right: 0px;
        animation: jumpInfinite 2s infinite;
        cursor: pointer;
        span{
            font-size: 30px;
            font-weight: 600;
            letter-spacing: -1.6px;
        }
        .pi{
            font-size: 30px;
            margin-right: 12px;
        }
    }
    @keyframes jumpInfinite {
        0% {
          margin-top: 0;
        }
        50% {
          margin-top: 30px;
        }
        100% {
          margin-top: 0;
        }
    }
    .header-text{
        width: 94%;
        .fade-text {
            opacity: 0;
            transition: opacity 0.75s ease-in-out;
            &.visible{
                opacity: 1;
            }
        }
    }
}
.video-section{
    padding-top: 5rem;
    width: 90%;
    margin: 0 auto;
    position: relative;
    .video-img{
        img{
            width: 100%;
            max-width: 1430px;
        }
    }
    .video-wrapper{
        width: 100%;
        max-width: 1430px;
        border-radius: 30px;
        margin: auto;
        .video{
            width: 100%;
            border-radius: 30px;
            cursor: pointer;
            object-fit: scale-down;
            margin: auto;
        }
    }
    .video-info{
        display:flex;
        gap: 160px;
        width: 100%;
        max-width: 1400px;
        margin: 50px auto 0px;
        h2{
            color: $white;
            font-size: 30px;
            font-weight: 400;
            letter-spacing: -1.5px;
            margin: 0px;
        }
    }
    .getintouch-button{
        border-radius: 30px;
        background: $accent;
        height: 105px;
        padding: 45px 65px;
        border: none;
        margin-top: 80px;
        .p-button-label{
            font-size: 40px;
            font-weight: 700;
            line-height: 41.588px;
            letter-spacing: -2px;
            position: relative;
            z-index: 2;
        }
        &:hover{
            background: $primary;
        }
    }
}
.esim-info-section{
    width: 95%;
    max-width: 1430px;
    margin: 8rem auto 2rem;
    position: relative;
    
    h2{
        font-size: 96px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -4.8px;
        color: $white;
        width:100%;
        max-width: 85%;
        margin: 0px;
        padding: 30rem 0rem 5rem;
        display: inline-block;
        white-space: pre;
        span{
            color: $primary;
        }
    }
    .typing-container {
        white-space: pre-wrap;
        overflow: hidden;
        min-height: 200px;
        position: relative;
        line-height: 100%;
    }
    .data2go-about-block{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%; 
    }
}
.white-label-info-section{
    position: relative;
    margin: 0rem auto 8rem;
    width: 90%;
    h2{
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -4.331px;
        color: $white;
        margin: 70px 0px 0px;
    }
    .typing-container{
        min-height: 312px;
        // visibility: hidden;
        line-height: 100%;
    }
    // &.start-animation {
    //     .typing-container{
    //         animation: cssAnimation 0s 3.5s forwards;
    //         visibility: hidden;
    //     }
    // }
    .data2go-white-label{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
}

@keyframes cssAnimation {
    to   { visibility: visible; }
}
.why-work-with-us-section{
    color: $white;
    position: relative;
    .container{
        width: 90%;
        max-width: 1430px;
        margin: 0rem auto;
    }
    .carousel-wrapper{
        overflow: hidden;
        width: 95%;
        .list{
            font-size: 50px;
            line-height: 105%;
        }
    }
    h2{
        font-size: 128px;
        font-weight: 700;
        line-height: 133.082px;
        letter-spacing: -6.4px;
        position: relative;
        z-index: 5;
        margin: 0px;
        &::before{
            content: '';
            background: $primary;
            width: 98px;
            height: 98px;
            z-index: -1;
            left: -35px;
            top: -28px;
            border-radius: 50%;
            position: absolute;
        }
    }
    h3{
        font-size: 53.182px;
        font-weight: 400;
        line-height: 55.841px;
        letter-spacing: -2.659px;
    }
    .half-circle{
        width:101px;
        height: 583px;
        position: absolute;
        right: 0rem;
        top: 21%;
        background: url('../../assets/images/half-circle.png') no-repeat;
    }
    .carousel-wrapper{
        &::before {
            content: "";
            z-index: 1;
            position: absolute;
            width: 100%;
            height:500px;
            pointer-events: none;
            top: 0;
            right: 0px;
            background: linear-gradient(0deg, hsla(0, 0%, 100%, 0) 0%, hsl(266.25deg 100% 6.27%) 100%);
        }
        &::after {
            content: "";
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 500px;
            pointer-events: none;
            bottom: 0;
            right: 0px;
            background: linear-gradient(0deg, hsl(266.25deg 100% 6.27%) 0%, hsla(0, 0%, 100%, 0) 100%);
        }
    }
}
.case-study-section{
    color: $white;
    width: 90%;
    max-width: 1430px;
    margin: 0px auto;
    padding: 15rem 0rem;
    h2{
        font-size: 48px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: -2.4px;
        margin: 0px 0px 65px;
    }
    .box{
        position: relative;
        transition: transform .2s;
        border-radius: 30px;
        h3{
            font-size: 40px;
            font-weight: 400;
            line-height: 42px;
            letter-spacing: -2px;
            position: absolute;
            top: 33%;
            left: 0px;
            right: 0px;
            margin: 0px;
        }
        .casestudy-img{
            display: block;
            line-height: 0px;
            border-radius: 30px;
            img{
                width: 100%;
                border-radius: 30px;
            }
        }
        &::before{
            content: '';
            border-radius: 30px;
            background: linear-gradient(104deg, rgba(15, 17, 69, 0.50) 2.08%, rgba(0, 0, 0, 0.25) 49.48%, rgba(15, 17, 69, 0.50) 96.88%);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
        }
        &:hover{
            transform: scale(1.02);
            cursor: pointer;
            &::before{
                background: $blue-bg;
                opacity: 0.85;
                border-radius: 30px;
            }
        }
    }
    .request-button{
        border-radius: 25px;
        background: $accent;
        height: 100px;
        padding: 45px 95px;
        border: none;
        margin-top: 90px;
        .p-button-label{
            font-size: 32px;
            font-weight: 400;
            line-height: 105%;
            letter-spacing: -1.624px;
            position: relative;
            z-index: 2;
        }
        &:hover{
            background: $primary;
        }
    }
}
.success-story-section{
    background: $white;
    padding: 10rem 8rem 10rem 175px;
    .container{
        width: 100%;
        margin: 0rem 0rem 5rem;
        h2{
            font-size: 96px;
            font-weight: 700;
            line-height: 103.971%;
            letter-spacing: -4.8px;
            margin: 0px;
            span{
                color: $primary;
                display: block;
            }
        }
        .getintouch-button{
            border-radius: 25px;
            background: $accent;
            padding: 45px 86px;
            border: none;
            font-size: 48px;
            font-weight: 700;
            line-height: 49px;
            letter-spacing: -2.4px;
            color: $white;
            display: inline-block;
            text-align: center;
            .p-button-label{
                font-size: 48px;
                font-weight: 700;
                line-height: 49px;
                letter-spacing: -2.4px;
                position: relative;
                z-index: 1;
            }
            &:hover{
                background: $primary;
            }
        }
    }
    .react-multi-carousel-list{
        .react-multi-carousel-track {
            .react-multi-carousel-item{
                .carousel-image{
                    .p-image{
                        img{
                            width: 100%;
                            max-width: 93%;
                            height: auto;
                            border-radius: 30px;
                        }
                    }
                    &:hover{
                        cursor: w-resize;
                    }
                }
            }
        }
        .react-multiple-carousel__arrow{
            background: $primary;
            opacity: 0;
            &:hover{
                background: $accent;
            }
        }
        &:hover{
            .react-multiple-carousel__arrow{
                opacity: 1;
            }
        }
    }
}
.affiliate-section{
    padding: 15rem 0rem;
    position: relative;
    .affiliate-container{
        width: 90%;
        max-width: 1222px;
        margin: 0 auto;
        background: $white;
        border-radius: 30px;
        padding: 25px;
        position: relative;
        z-index: 1;
        transition: transform .2s;
        .p-image{
            display: block;
            line-height: 0px;
            img{
                width: 100%;
                max-width: 718px;
                border-radius: 30px;
            }
        }
        h2{
            margin: 0px;
            font-size: 64px;
            font-weight: 400;
            line-height: 67px;
            letter-spacing: -3.2px;
            text-align: left;
            margin-top: 5rem;
        }
        .arrow-icon{
            width: 90px;
            height: 90px;
            border-radius: 50px;
            background: $black;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s ease-out;
            .pi{
                color: $white;
                font-size: 45px;
                transition: all 0.3s ease-out;
            }
            &:hover{
                background: $primary;
                .pi{
                    margin-left: 10px;
                    margin-top: -12px;
                }
            }
        }
        &:hover{
            transform: scale(1.03);
            cursor: pointer;
            .arrow-icon{
                background: $primary;
                .pi{
                    margin-left: 10px;
                    margin-top: -15px;
                }
            }
        }
    }
    .link-container{
        width: 100%;
        max-width: 1222px;
        margin: 1.5rem auto;
        p{
            margin: 0px;
            a{
                color: $white;
                font-size: 20px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: -1px; 
            }
        }
    }
    &::before{
        content: '';
        width: 365px;
        height: 365px;
        background: $primary;
        position: absolute;
        border-radius: 100%;
        left: 4rem;
        bottom: 12rem;
        z-index:0;
        filter: blur(250px);
        -webkit-filter: blur(250px);
    }
    &::after{
        content: '';
        width: 225px;
        height: 225px;
        background: $accent;
        position: absolute;
        border-radius: 100%;
        right: 10rem;
        top: 6rem;
        z-index:0;
        filter: blur(150px);
        -webkit-filter: blur(150px);
    }
}

// Variables
$item-count: 3;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25) - 10%}, #{$i * 25%} {
      transform: translateY((-100% / $item-count) * $i);
    }
  }
}


.rect1, .rect2, .rect3 {
    animation: none;
}

/* Rectangle Animation */
.start-animation .rect1 {
    animation: growHeight1 2s ease forwards;
    animation-delay: 0s;
}
.start-animation .rect2 {
    animation: growHeight2 2s ease forwards;
    animation-delay: 1s;
}
.start-animation .rect3 {
    animation: growHeight3 2s ease forwards;
    animation-delay: 2s; 
}

/* Keyframes for first rectangle */
@keyframes growHeight1 {
    0% {
        height: 0;
        y: 908; 
    }
    100% {
        height: 342;
        y: 566;
    }
}

/* Keyframes for second rectangle */
@keyframes growHeight2 {
    0% {
        height: 0;
        y: 908;
    }
    100% {
        height: 714;
        y: 194;
    }
}

/* Keyframes for third rectangle */
@keyframes growHeight3 {
    0% {
        height: 0;
        y: 908;
    }
    100% {
        height: 908;
        y: 0;
    }
}

/* Path Animation */
.path {
    transform-origin: center;
    animation: none;
    opacity: 0;
}
.start-animation #path1 {
    animation: scaleUp 2s ease-in-out forwards 0s, pathColorChange 4s linear infinite;
}
.start-animation #path2 {
    animation: scaleUp 2s ease-in-out forwards 0.5s, pathColorChange 4s linear infinite 0.5s;
}
.start-animation #path3 {
    animation: scaleUp 2s ease-in-out forwards 1s, pathColorChange 4s linear infinite 1s;
}
.circle{
    animation: none;
    opacity: 0;
}
.start-animation .circle{
    animation: 3.5s ease 0s normal forwards 1 fadein;
}

@keyframes fadein{
    0% { opacity:0; transform: scale(0); }
    66% { opacity:0; }
    100% { opacity:1; transform: scale(1); }
}

/* Scaling animation for paths */
@keyframes scaleUp {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Animation for the path color change */
@keyframes pathColorChange {
    0% {
        stroke: #0F1145;
    }
    50% {
        stroke: #252AAB;
    }
    100% {
        stroke: #0F1145;
    }
}

.dots-container {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 999;
    right: 2rem;
}
  
.dot {
    width: 16px;
    height: 16px;
    background-color: $white;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    border: 1px solid $main-bg;
    &:hover{
        &::before{
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: $white;
            left: 0px;
            right: 0px;
            margin: 0 auto;
            top: 1px;
            bottom: 0px;
            border-radius: 50%;
            border: 2px solid $primary;
        }
    }
    &.home{
        background: url(../../assets/images/home-icon.png) no-repeat;
        background-color: transparent;
        width: 17px;
        height: 17px;
        border-radius: 0px;
        background-position: center;
        background-size: 100%;
        border: none;
        filter: drop-shadow(1px 1px 0 $main-bg) 
        drop-shadow(-1px -1px 0 $main-bg);
        &:hover{
            background: url(../../assets/images/home-icon-hover.png) no-repeat;
            &::before{
                display: none;
            }
        }
    }
}
.dot:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
.tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $primary;
    color: white;
    padding: 4px 5px 6px;
    border-radius: 5px;
    white-space: nowrap;
    transition: opacity 0.3s ease;
    font-size: 14px;
    &::before{
        content: '';
        border-top: 6px solid transparent; 
        border-bottom: 6px solid transparent;
        position: absolute;
        right: -6px;
        border-left: 6px solid $primary;
        top: 7px;
    }
}

.dots-hide{
    .dots-container{
        display: none;
    }
}

.vertical-slider-section{
    position: relative;
    width: 98%;
    margin: 0 auto;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 350px;
        bottom: -2px;
        background: linear-gradient(360deg, #FFF 0%, rgba(255, 255, 255, 0.80) 50%, rgba(255, 255, 255, 0.00) 100%);
    }
}
.carousel-container {
    position: relative;
    width: 100%;
    height: 800px;
    overflow: hidden;
}
.carousel-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    transition: transform 1.5s ease;
}
.carousel-item {
    width: 100%;
    height: auto;
    max-height: 290px;
    border-radius: 30px;
    overflow: hidden;
    flex-shrink: 0;
}
.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


// Responsive CSS

@media (max-width:991px){
    .header-text-section{
        padding: 80px 20px;
        h1{
            font-size: 50px;
            line-height: 110%;
            padding-left: 0px;
            letter-spacing: -2.8px;
        }
        .learn-arrow{
            right: -35px;
            span, .pi{
                font-size: 24px;
            }
        }
    }
    .video-section{
        .video-info{
            flex-direction: column;
            gap: 50px;
            h2{
                font-size: 28px;
                padding: 0rem 4rem;
            }
        }
        .getintouch-button{
            padding: 34px 86px;
            border-radius: 18px;
            margin-top: 70px;
            height:68px;
            .p-button-label{
                font-size: 20px;
                letter-spacing: -1px;
            }
        }
        video{
            position: relative;
            z-index: 9999;
        }
    }
    .esim-info-section{
        margin: 6rem auto;
        h2{
            padding: 4rem 0rem 0rem;
            font-size: 62px;
            line-height: 110%;
            letter-spacing: -2px;
        }
        .typing-container{
            min-height: 250px;
        }
        svg{
            height: 100%;
        }
    }
    .white-label-info-section{
        margin: 5rem auto;
        h2{
            padding: 2rem 0rem 2rem;
            font-size: 40px;
            line-height: 114%;
            letter-spacing: -2px;
            margin: 20px 0px 0px;
        }
        .data2go-white-label{
            .logo-img{
                img{
                    width: 100%;
                    max-width: 75px;
                }
            }
        }
        .typing-container {
            min-height: 135px;
        }
    }
    .why-work-with-us-section{
        padding-top: 3rem;
        overflow: hidden;
        h2{
            font-size: 48px;
            line-height: 104%;
            letter-spacing: -2.4px;
            margin-left: 4rem;
        }
        .half-circle{
            display: none;
        }
        .carousel-wrapper{
            margin-top:4rem;
            .list{
                font-size: 28px;
                font-weight: 400;
                line-height: 105%;
                letter-spacing: -1.359px;
                padding: 0rem 2rem;
            }
            &::before{
                top:8rem
            }
        }
    }
    .case-study-section{
        padding: 4rem 0rem;
        h2{
            font-size: 30px;
            line-height: 114%;
            letter-spacing: -1.5px;
            margin: 0px 0px 40px;
        }
        .box h3{
            font-size: 27px;
            font-weight: 400;
            line-height: 105%;
            letter-spacing: -1.345px;
        }
        .request-button{
            border-radius: 16px;
            height: 68px;
            padding: 32px 95px;
            margin-top: 40px;
            .p-button-label{
                font-size: 18px;
                letter-spacing: -0.9px;
            }
        }
    }
    .success-story-section {
        padding: 4rem 0rem;
        .container{
            flex-direction: column;
            margin: 0rem auto 3rem;
            h2{
                font-size: 38px;
                line-height: 104%;
                letter-spacing: -1.8px;
                text-align: center;
            }
            .getintouch-button{
                border-radius: 16px;
                height: 68px;
                padding: 10px 95px;
                margin-top: 40px;
                font-size: 20px;
                letter-spacing: -0.9px;
                .p-button-label{
                    font-size: 18px;
                    letter-spacing: -0.9px;
                } 
            }
        }
        .react-multi-carousel-list{
            width: 80%;
            margin: 0 auto;
            .carousel-image .p-image img{
                height: auto !important;
                width: 90% !important;
            }
        }
    }
    .affiliate-section{
        padding: 3rem 0rem;
        overflow: hidden;
       .affiliate-container{
            h2{
                font-size: 38px;
                line-height: 105%;
                letter-spacing: -1.9px;
                margin-top: 0rem;
            }
            .right-block{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
            }
            .arrow-icon{
                width: 55px;
                height: 55px;
                .pi{
                    font-size: 24px;
                }
            }
       }
       &::before{
            width: 125px;
            height: 125px;
       }
       &::after{
        width: 125px;
        height: 125px;
   }
    }
    .scroll-container{
        display: none;
    }
    .carousel-list {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

@media (min-width:1200px) and (max-width:1480px){
    .header-text-section{
        padding-bottom: 10rem;
        padding-top: 8rem;
        h1{
            font-size: 90px;
            line-height: 98px;
            padding-left: 4rem;
        }
    }
    .video-section{
        .video-info {
            margin: 30px auto 0px;
            h2{
                font-size: 22px;
            }
        }
        .getintouch-button{
            height: 90px;
            padding: 30px 65px;
            margin-top: 60px;
            border-radius: 25px;
            .p-button-label{
                font-size: 36px;
            }
        }
    }
    .esim-info-section {
        h2{
            font-size: 66px;
            line-height: 96px;
            letter-spacing: -3.8px;
            padding: 20rem 0rem 5rem;
        }
        .bars-svg{
            width: 80%;
            display: flex;
            margin: 0 auto;
        }
    }
    .white-label-info-section{
        padding: 1rem 0rem;
        h2{
            font-size: 66px;
            line-height: 96px;
            letter-spacing: -3.8px;
        }
    }
    .case-study-section{
        padding: 8rem 0rem;
    }
    .affiliate-section{
        padding: 10rem 0rem;
    }
    .why-work-with-us-section{
        h2{
            font-size: 100px;
            line-height: 108px;
        }
        .carousel-wrapper{
            &::before{
                right: 0px;
            }
            &::after{
                right: 0px;
            }
            .list{
                font-size: 40px;
                margin: 20px 0px;
            }
        }
    }
    .success-story-section{
        padding: 10rem 5rem 10rem 90px;
        .container{
            h2{
                font-size: 70px;
                line-height: 100%;
            }
            .getintouch-button{
                padding: 30px 65px;
                border-radius: 25px;
                font-size: 36px;
                .p-button-label{
                    font-size: 36px;
                }
            }
        }
        
        .react-multi-carousel-list{
            .react-multi-carousel-track{
                .react-multi-carousel-item{
                    .carousel-image{
                        .p-image img{
                            height: auto;
                            width: 92%;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:1481px) and (max-width:1690px){
    .success-story-section .container{
        h2 {
            font-size: 75px;
        }
        .getintouch-button{
            padding: 40px 85px;
            font-size: 45px;
        }
    }
}

@media (max-width:600px){
    .white-label-info-section{
        margin: 3rem auto;
        h2{
            font-size: 40px;
            margin: 0px 0px 0px;
        }
        .typing-container{
            line-height: 120%;
            letter-spacing: -1.5px;
            br{
                display: none;
            }
        }
    }
    .vertical-slider-section{
        width: 90%;
    }
    .carousel-list {
        grid-template-columns: repeat(2, 1fr) !important;
    }
    .header-text-section h1{
        font-size: 46px;
        line-height: 106%;
    }
    .video-section{
        padding-top: 2rem;
        .video-wrapper .video{
            border-radius: 35px;
            min-height: 325px;
            object-fit: cover;
        }
        .video-info h2 {
            font-size: 28px;
            padding: 0rem 1rem;
        }
    }
    .esim-info-section {
        h2{
            font-size: 38px;
            line-height: 120%;
        }
        .typing-container{
            min-height: 220px;
            line-height: 114%;
        }
    }
    .why-work-with-us-section .carousel-wrapper .list{
        font-size: 26px;
        line-height: 110%;
        margin: 26px 0px;
    }
    .case-study-section .request-button{
        padding: 30px 50px;
    }
    .success-story-section .container h2 {
        font-size: 36px;
        line-height: 110%;
    }
    .affiliate-section .affiliate-container .p-image img{
        border-radius: 12px;
    }
}

@media (min-width:993px) and (max-width:1199px){
    .header-text-section{
        padding-bottom: 10rem;
        padding-top: 8rem;
        h1{
            font-size: 90px;
            line-height: 98px;
            padding-left: 4rem;
        }
    }
    .video-section .video-info h2{
        font-size: 22px;
    }
    .case-study-section{
        .box h3{
            font-size: 26px;
            line-height: 28px;
        }
    }
    .esim-info-section {
        h2{
            font-size: 52px;
            line-height: 75px;
            letter-spacing: -3.8px;
            padding: 20rem 0rem 5rem;
        }
        .bars-svg{
            width: 80%;
            display: flex;
            margin: 0 auto;
        }
    }
    .white-label-info-section{
        padding: 2rem 0rem;
        h2{
            font-size: 52px;
            line-height: 75px;
            letter-spacing: -3.8px;
        }
    }
    .why-work-with-us-section{
        h2{
            font-size: 90px;
            line-height: 98px;
        }
        .carousel-wrapper{
            &::before{
                right: 0px;
            }
            &::after{
                right: 0px;
            }
            .list{
                font-size: 40px;
                margin: 20px 0px;
            }
        }
    }
    .success-story-section{
        padding: 10rem 5rem 10rem 90px;
        .container{
            width: 95%;
            h2{
                font-size: 55px;
                line-height: 110%;
            }
            .getintouch-button{
                padding: 30px 25px;
                font-size: 40px;
            }
        }
        .react-multi-carousel-list{
            .react-multi-carousel-track{
                .react-multi-carousel-item{
                    .carousel-image{
                        .p-image img{
                            height: auto;
                            width: 92%;
                        }
                    }
                }
            }
        }
    }
    .affiliate-section .affiliate-container h2{
        font-size: 46px;
        line-height: 100%;
        margin-top: 3.5rem;
    }
}
video::-webkit-media-controls-start-playback-button {
    display: none;
}

// Responsive CSS for large screens

@media (min-width:1940px) {
    .header-text-section{
        padding-bottom: 33rem;
        padding-top: 22rem;
        h1{
            font-size: 128px;
            line-height: 134px;
            letter-spacing: -6.4px;
        }
    }
    .video-section{
        padding-top: 16rem;
        .video-info{
            margin: 55px auto 0px;
            h2{
                font-size: 32px;
            }
        }
        .getintouch-button{
            height: 112px;
            padding: 45px 66px;
            margin-top: 100px;
            .p-button-label{
                font-size: 40px;
            }
        }
    }
    .esim-info-section{
        margin: 25rem auto 2rem;
        h2{
            font-size: 96px;
            padding: 30rem 0rem 6rem;
        }
    }
    .white-label-info-section{
        margin: 15rem auto;
        h2{
            font-size: 86px;
            margin: 70px 0px 0px;
        }
    }
    .why-work-with-us-section{
        h2{
            font-size: 128px;
        }
        h3{
            font-size: 53px;
        }
    }
    .case-study-section{
        padding: 18rem 0rem;
        h2{
            font-size: 48px;
            margin: 0px 0px 65px;
        }
    }
}