@import '../../assets/scss/variables';

.header-banner-section{
    width: 90%;
    max-width: 1420px;
    margin: 0 auto;
    padding: 5rem 0rem;
    h1{
        font-size: 128px;
        font-weight: 600;
        line-height: 100%; /* 128px */
        letter-spacing: -6.4px;
        color: $white;
        margin: 0px;
    }
    .open-button{
        border-radius: 30px;
        background: $accent;
        height: 112px;
        padding: 45px 66px;
        border: none;
        margin-top: 80px;
        .p-button-label{
            font-size: 40px;
            font-weight: 700;
            line-height: 41.588px;
            letter-spacing: -2px;
            position: relative;
            z-index: 2;
        }
        &:hover{
            background: $primary;
        }
    }
}
.life-at-bluehouse-section{
    padding: 15rem 0rem;
    position: relative;
    .life-at-bluehouse-container{
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        h2{
            font-size: 64px;
            font-weight: 500;
            line-height: 105%;
            letter-spacing: -3.2px;
            color: $white;
        }
        .p-image{
            img{
                border-radius: 26px;
                width: 100%;
            }
        }
        .right{
            .p-image{
                margin-bottom: 35px;
                display: block;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }
    &::before{
        content: '';
        width: 365px;
        height: 365px;
        background: $primary;
        position: absolute;
        border-radius: 100%;
        left: 8rem;
        bottom: 8rem;
        z-index:0;
        filter: blur(250px);
        -webkit-filter: blur(250px);
    }
    &::after{
        content: '';
        width: 225px;
        height: 225px;
        background: $accent;
        position: absolute;
        border-radius: 100%;
        right: 18rem;
        top: 15rem;
        z-index:0;
        filter: blur(150px);
        -webkit-filter: blur(150px);
    }
}
.review-section{
    background: #160033;
    padding: 8rem 0rem;
    position: relative;
    z-index: 1;
    .review-container{
        width: 90%;
        max-width: 1500px;
        margin: 0 auto;
        color: $white;
        h2{
            font-family: Inter;
            font-size: 96px;
            font-weight: 600;
            line-height: 120%;
            letter-spacing: -4.8px;
            span{
                color: $primary;
            }
        }
        p{
            font-size: 36px;
            font-weight: 400;
            line-height: 105%;
            letter-spacing: -1.8px;
        }
        .user-avator{
            img{
                border: 2px solid $white;
                border-radius: 50%;
                padding: 20px;
            }
        }
    }
}
.our-values-section{
    width: 90%;
    max-width: 1675px;
    margin: 0px auto;
    padding: 12rem 0rem;
    h2{
        margin: 0px;
        font-size: 64px;
        font-weight: 600;
        line-height: 105%;
        letter-spacing: -3.2px;
        color: $white;
    }
    .p-accordion{
        margin-top: 5rem;
        .p-accordion-tab{
            margin-bottom: 2rem;
            .p-accordion-header{
                background: $accent;
                border-radius: 30px;
                .p-accordion-header-link{
                    background: none;
                    border: none;
                    padding: 55px 75px;
                    .p-accordion-header-text{
                        color: $white;
                        font-size: 60px;
                        font-weight: 700;
                        line-height: 103.971%;
                        letter-spacing: -3.2px;
                    }
                    .p-icon{
                        display: none;
                    }
                }
            }
            .p-toggleable-content{
                width: 100%;
                max-width: 55%;
                opacity: 0;
                .p-accordion-content{
                    border: none;
                    padding: 0px;
                    p{
                        font-size: 36px;
                        font-weight: 600;
                        letter-spacing: -1.8px;
                    }
                }
            }
            &.p-accordion-tab-active{
                justify-content: space-between;
                display: flex;
                background: $white;
                border-radius: 30px;
                padding: 4rem 5rem;
                min-height: 365px;
                .p-accordion-header{
                    max-width: 35%;
                    width: 100%;
                    background: transparent;
                    .p-accordion-header-link{
                        padding: 10px 0px;
                        background: transparent;
                        .p-accordion-header-text{
                            color: $primary;
                        }
                        &:focus-visible{
                            box-shadow:none;
                        }
                    }
                }
                .p-toggleable-content{
                    opacity: 1;
                }
            }
        }
    }
}
.positions-section{
    width: 90%;
    max-width: 1650px;
    margin: 0px auto;
    padding: 5rem 0rem;
    h2{
        margin: 0px;
        font-size: 96px;
        font-weight: 700;
        line-height: 105%;
        letter-spacing: -3.2px;
        color: $white;
        margin-bottom: 5rem;
    }
    .job-list{
        margin-bottom: 8rem;
        color: $white;
        &:last-child{
            margin-bottom: 0rem;
        }
        h3{
            font-size: 40px;
            font-weight: 700;
            line-height: 105%;
            letter-spacing: -2px;
            text-shadow: 0px 0px 250px $black;
            margin: 0px;
            .pi-map-marker{
                font-size: 30px;
                margin-right: 1rem;
            }
        }
        p{
            font-size: 36px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -1.8px;
        }
        .apply-button{
            border-radius: 30px;
            background: $accent;
            height: 100px;
            padding: 45px 86px;
            border: none;
            .p-button-label{
                font-size: 40px;
                font-weight: 700;
                line-height: 41.588px;
                letter-spacing: -2px;
                position: relative;
                z-index: 2;
            }
            &:hover{
                background: $primary;
            }
        }
        .more-link{
            font-size: 30.519px;
            font-weight: 700;
            line-height: 103.971%;
            letter-spacing: -1.526px;
            color: $white;
            display: flex;
            align-items: center;
            gap: 12px;
            margin-left: 2.5rem;
            .pi-download{
                border: 2px solid $white;
                border-radius: 30px;
                display: flex;
                width: 38px;
                height: 38px;
                align-items: center;
                justify-content: center;
            }
            &:hover{
                color: $primary;
                .pi-download{
                    border: 1px solid $primary;
                }
            }
        }
        .custom-margin{
            margin-top: 35px;
        }
    }
}
.location-map-section{
    width: 90%;
    max-width: 1420px;
    margin: 0px auto;
    padding: 10rem 0rem;
    text-align: center;
    color: $white;
    .p-image{
        img{
            width: 100%;
            max-width: 1415px;
        }
    }
    h3{
        margin: 0px;
        font-size: 32px;
        font-weight: 300;
        line-height: 103.971%;
        letter-spacing: -1.6px;
    }
    .our-location{
        h3{
            display: inline-block;
            margin: 1rem 2rem;
        }
    }
}
.team-section{
    width: 90%;
    max-width: 1240px;
    margin: 0px auto;
    padding: 10rem 0rem 15rem;
    text-align: center;
    h2{
        margin: 0px;
        font-size: 64px;
        font-weight: 600;
        line-height: 105%;
        letter-spacing: -3.2px;
        color: $white;
        margin-bottom: 1rem;
    }
    .p-image{
        img{
            width: 100%;
            max-width: 355px;
            margin-top: 5rem;
        }
    }
}

// Responsive CSS

@media (max-width:991px){
    .header-banner-section{
        padding: 80px 0px 0px;
        h1{
            font-size: 38px;
            line-height: 120%;
            letter-spacing: -1.9px;
        }
        .open-button{
            padding: 34px 86px;
            border-radius: 18px;
            margin-top: 40px;
            height:68px;
            .p-button-label{
                font-size: 18px;
                letter-spacing: -0.9px;
            }
        }
    }
    .life-at-bluehouse-section{
        padding: 3rem 0rem;
        .life-at-bluehouse-container{
            h2{
                font-size: 34px;
                line-height: 104%;
                letter-spacing: -1.7px;
            }
            .mobile-flex{
                flex-direction: column;
                .p-image img{
                    height: 300px;
                    object-fit: cover;
                }
                .right{
                    display: flex;
                    gap: 15px;
                }
            }
        }
        &::before{
            width: 165px;
            height: 165px;
        }
        &::after{
            width: 165px;
            height: 165px;
        }
    }
    .review-section{
        padding: 4rem 0rem;
        .review-container{
            .user-avator img{
                width: 109px;
                padding: 6px;
            }
            h2{
                font-size: 25px;
                font-weight: 600;
                line-height: 128%;
                letter-spacing: -1.252px;
            }
            p{
                font-size: 18px;
                line-height: 105%;
                letter-spacing: -0.9px;
                &.text-right{
                    text-align: center !important;
                }
            }
        }
    }
    .our-values-section{
        padding: 4rem 0rem;
        h2{
            font-size: 42px;
            line-height: 105%;
            letter-spacing: -2.1px;
        }
        .p-accordion{
            margin-top: 2rem;
            .p-accordion-tab{
                &.p-accordion-tab-active{
                    flex-direction: column;
                    justify-content: flex-start;
                    padding: 30px;
                    min-height: auto;
                    border-radius: 15px;
                    .p-accordion-header{
                        max-width: 100%;
                        .p-accordion-header-link{
                            .p-accordion-header-text{
                                font-size: 27px;
                                line-height: 103.971%;
                                letter-spacing: -1.351px;
                            }
                        }
                    }
                }
                .p-accordion-header .p-accordion-header-link{
                    border-radius: 15px;
                    padding: 27px 30px;
                    .p-accordion-header-text{
                        font-size: 27px;
                        line-height: 103.971%;
                        letter-spacing: -1.351px;
                    }
                }
                .p-toggleable-content{
                    max-width: 100%;
                    .p-accordion-content{
                        p{
                            font-size: 16px;
                            letter-spacing: -0.823px;
                        }
                    }
                }
            }
        }
    }
    .positions-section{
        padding: 0rem 0rem;
        h2{
            font-size: 42px;
            line-height: 105%;
            letter-spacing: -2.1px;
            text-align: center;
            margin-bottom: 4rem;
        }
        .job-list{
            margin-bottom: 4rem;
            h3{
                font-size: 32px;
                line-height: 105%;
                letter-spacing: -1.6px;
                &.location-h3{
                    text-align: left;
                    font-size: 20px;
                    letter-spacing: -1px;
                }
                .pi-map-marker{
                    font-size: 20px;
                    margin-right: 0.6rem;
                }
            }
            p{
                font-size: 18px;
                line-height: 130%;
                letter-spacing: -0.9px;
            }
           .apply-button{
                border-radius: 15px;
                padding: 10px 70px;
                height: auto;
                .p-button-label{
                    font-size: 18px;
                    letter-spacing: -0.9px;
                }
           }
           .more-link{
                font-size: 18px;
                line-height: 103%;
                letter-spacing: -0.9px;
                margin-left: 0.5rem;
                .pi-download{
                    width: 30px;
                    height: 30px;
                    font-size: 13px;
                }
           }
        }
    }
    .location-map-section{
        padding: 4rem 0rem 0rem;
        h3{
            font-size: 20px;
            line-height: 103%;
            letter-spacing: -1px;
        }
    }
    .team-section{
        padding: 4rem 0rem 6rem;
        h2{
            font-size: 42px;
            line-height: 105%;
            letter-spacing: -2.1px;
            margin-bottom: 3rem;
        }
       .p-image img{
            margin-top: 0.4rem;
       }
    }
}
@media (min-width:960px) and (max-width:1199px){
    .header-banner-section h1{
        font-size: 90px;
    }
    .review-section .review-container h2{
        font-size: 66px;
    }
    .positions-section h2{
        font-size: 90px;
    }
}
@media (min-width:1200px) and (max-width:1480px){
    .header-banner-section h1{
        font-size: 90px;
    }
    .review-section .review-container h2{
        font-size: 66px;
    }
    .positions-section h2{
        font-size: 90px;
    }
}