@import '../../assets/scss/variables';

.getintouch-section{
    &.questions-section{
        .container{
            max-width: 1400px;
            .form-block{
                .p-inputtext{
                    text-align: left;
                }
                .p-dropdown{
                    text-align: left;
                }
            }
        }
    }
}